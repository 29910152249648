import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar.component';
import Homepage from './pages/homepage.component';
import { Switch, Route, Redirect, Routes } from 'react-router-dom';
import Treatments from './pages/treatments.component';
import ContactUs from './pages/contactus.component';
import PartnersPage from './pages/partners.component';
import Footer from './components/footer.component';
// import {Switch};


function App() {
  return (
    <section>
      <Navbar />
      {/* <Homepage /> */}

      {/* <Switch> - in react router 6 we use routes instead of switch */}
      <Routes >
        {/* <Route exact path='/' component={HomePage} /> */}
        <Route exact path='/' element={<Homepage/>}/>
        <Route exact path='/home' element={<Homepage/>} />

        <Route path='/treatments' element={<Treatments/>} />
        <Route path='/contactus' element={<ContactUs/>} />
        <Route path='/partners' element={<PartnersPage/>} />
      </Routes>
      <Footer/>

      {/* <Route path='/showroom' render={props => <ShowroomWithSpinner isLoading={isLoading} {...props}/> } />        
        <Route exact path='/product/:id' render={props => <ProductPageWithSpinner isLoading={isLoading} {...props}/> } />        
        <Route path='/finance' component={FinancePage} />
        <Route path='/contactus' component={Contactus} />
        <Route path='/dashboard' component={Dashboard} />
        <Route exact path='/checkout' component={CheckoutPage} /> */}

      {/* </Switch> */}
    </section>
  );
}

export default App;
