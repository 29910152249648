import { React } from 'react';
import { ReactComponent as DropboxLogo } from '../assets/zospace-assets/logos/dropbox-gray.svg';
import { ReactComponent as DDLogo } from '../assets/zospace-assets/logos/ddnew3.svg';
import extr1 from '../assets/zospace-assets/images/extraction1.png';
import extr2 from '../assets/zospace-assets/images/extraction2.jpg'
import filling from '../assets/zospace-assets/images/fillings1.jpg'
import mouthguard from '../assets/zospace-assets/images/mouthgard.jpg'
import mg1 from '../assets/zospace-assets/images/mg1.jpg'
import denture from '../assets/zospace-assets/images/dentures.jpg'
import polish from '../assets/zospace-assets/images/polishing.jpg'
import root from '../assets/zospace-assets/images/rootCanal.jpg'
import bridge from '../assets/zospace-assets/images/bridge.jpg'
import kids from '../assets/zospace-assets/images/kids.jpg'
import kids3 from '../assets/zospace-assets/images/kids3.jpg'
import kids4 from '../assets/zospace-assets/images/p2.jpg'
import crown from '../assets/zospace-assets/images/crowns.jpg'
import xray from '../assets/zospace-assets/images/xray.jpg'
import xray1 from '../assets/zospace-assets/images/xray1.jpg'
import xray2 from '../assets/zospace-assets/images/xray2.jpg'
import xray3 from '../assets/zospace-assets/images/xray3.png'
import kids2 from '../assets/zospace-assets/images/kids2.jpg'
import consultation from '../assets/zospace-assets/images/consultation.jpg'
import cons1 from '../assets/zospace-assets/images/cons1.jpg'
import cons2 from '../assets/zospace-assets/images/cons2.jpg'
import cons3 from '../assets/zospace-assets/images/cons3.jpg'
import gd1 from '../assets/zospace-assets/images/gd1.jpg'
import gd2 from '../assets/zospace-assets/images/gd2.jpg'
import gd3 from '../assets/zospace-assets/images/gd3.jpg'
import gd4 from '../assets/zospace-assets/images/gd4.jpg'
import gd5 from '../assets/zospace-assets/images/gd5.jpg'
import gd6 from '../assets/zospace-assets/images/gd6.jpg'
import gd7 from '../assets/zospace-assets/images/gd7.jpg'
import gd8 from '../assets/zospace-assets/images/gd8.jpg'
import cd1 from '../assets/zospace-assets/images/cd1.jpg'
import cd2 from '../assets/zospace-assets/images/cd2.jpg'
import cd3 from '../assets/zospace-assets/images/cd3.jpg'
import cd4 from '../assets/zospace-assets/images/cd4.jpg'
import cd5 from '../assets/zospace-assets/images/cd5.jpg'
import dent1 from '../assets/zospace-assets/images/dent1.jpg'
import dent2 from '../assets/zospace-assets/images/dent2.jpg'
import dent3 from '../assets/zospace-assets/images/dent3.jpg'
import white1 from '../assets/zospace-assets/images/white1.jpg'
import white2 from '../assets/zospace-assets/images/white2.jpg'


import { ReactComponent as LineTop } from '../assets/zospace-assets/lines/line-top.svg';

import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react';


export default function Treatments() {


  const images = [
    {
      id: 1,
      image: extr1
    },
    {
      id: 2,
      image: extr2
    },
    {
      id: 1,
      image: filling
    },
    {
      id: 1,
      image: mouthguard
    },
    {
      id: 1,
      image: denture
    },
    {
      id: 1,
      image: polish
    },
    {
      id: 1,
      image: root
    },
    {
      id: 1,
      image: bridge
    },
    {
      id: 1,
      image: kids
    },
    {
      id: 1,
      image: crown
    },
    {
      id: 1,
      image: xray
    },
    {
      id: 1,
      image: kids2
    },
    {
      id: 1,
      image: consultation
    }

  ]

  const [display, setDisplay] = useState(1);

  const handleChange = (event) => {
    console.log('event.target.value', event.target.value);
    setDisplay(~~event.target.value);
    console.log('state', display);
  };

  function toDisplay(val) {
    console.log('state 2', display);
    console.log('state 2 typeof', typeof display);
    console.log('val ', val);


    let toReturn = ''
    switch (display) {
      case 1:
        toReturn = (
          <div>
            <div className='text-center'>
              <p className="mb-8 mb-12 2xl:mb-12 text-gray-400 text-xl text-center">
                The dental consultation is a non-invasive visit with your dentist where you can discuss any issues that you're experiencing, concerns and treatment options. If you have anxieties about having dental work done, this is also the time to express it.
                Dental consultations enables the dentist to closely monitor your oral health for any early symptoms that may not be noticeable to you.
              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={consultation} alt="consultations" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={cons1} alt="consultations" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={cons2} alt="consultations" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={cons3} alt="consultations" />
              </div>
              {/* <div className="w-full lg:w-1/3 p-4">
              <img className="w-full h-96 object-cover" src="https://images.unsplash.com/photo-1618005198920-f0cb6201c115?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt="" />
            </div> */}
            </div>
          </div>
        )
        break;
      case 2:
        toReturn = (
          <div>
            <div className='text-center'>
              <p className="mb-8 mb-12 2xl:mb-12 text-gray-400 text-xl text-center">
                Dental X-rays (radiographs) are images of your teeth that your dentist uses to evaluate your oral health. These X-rays are used with low levels of radiation to capture images of the interior of your teeth and gums. This can help your dentist to identify problems, like cavities, tooth decay, and impacted teeth.
                <br />
                Dental X-rays may seem complex, but they’re actually very common tools that are just as important as your teeth cleanings.

              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={xray} alt="xrays" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={xray1} alt="xrays" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={xray2} alt="xrays" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={xray3} alt="xrays" />
              </div>
              {/* <div className="w-full lg:w-1/3 p-4">
          <img className="w-full h-96 object-cover" src="https://images.unsplash.com/photo-1618005198920-f0cb6201c115?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt="" />
        </div> */}
            </div>
          </div>)
        break;
      case 3:
        toReturn = (
          <div>
            <div className='text-center'>
              <p className="mb-8 mb-12 2xl:mb-12 text-gray-400 text-xl text-center">
                A general dentist is your primary care dental provider. This dentist diagnoses, treats, and manages your overall oral health care needs, including gum care, root canals,
                fillings, crowns, veneers, bridges, and preventive education.
              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={gd1} alt="general dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={gd2} alt="general dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={gd3} alt="general dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={gd4} alt="general dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={gd5} alt="general dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={gd6} alt="general dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={gd7} alt="general dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={gd8} alt="general dentistry" />
              </div>
            </div>
          </div>)

        break;
      case 4:
        toReturn = (
          <div>
            <div className='text-center'>
              <p className="mb-8 mb-12 2xl:mb-12 text-gray-400 text-xl text-center">
                Cosmetic dentistry refers to any dental work that improves the appearance (though not necessarily functionality) of teeth, gums and/or bite. It primarily focuses on dental aesthetics in color, position, shape, size, alignment and overall smile appearance.              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={cd1} alt="cosmetic dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={cd2} alt="cosmetic dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={cd3} alt="cosmetic dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={cd4} alt="cosmetic dentistry" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={cd5} alt="cosmetic dentistry" />
              </div>
            </div>
          </div>)

        break;
      case 5:
        toReturn = (
          <div>
            <div className='text-center'>
              <p className="mb-8 mb-12 2xl:mb-12 text-gray-400 text-xl text-center">
                Mouth guards and splints even out the pressure across your jaw and create a physical barrier between your upper and lower teeth to protect them from further damage. They can also reduce any grinding noises you make at night. Mouth guards are similar to those used in sports such as boxing or rugby.
              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={mouthguard} alt="Mouthguard" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-full" src={mg1} alt="Mouthguard" />
              </div>
              {/* <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd3} alt="cosmetic dentistry" />
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd4} alt="cosmetic dentistry" />
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd5} alt="cosmetic dentistry" />
            </div> */}
            </div>

          </div>)

        break;
      case 6:
        toReturn = (
          <div>
            <div className='text-center'>
              <p className="mb-8 mb-12 2xl:mb-12 text-gray-400 text-xl text-center">
                Pediatric dentists provide comprehensive oral health care that includes the following:
                <ul>
                  <li> Infant oral health exams, which include risk assessment for caries in mother and child</li>
                  <li> Preventive dental care including cleaning and fluoride treatments, as well as nutrition and diet recommendations</li>
                  <li> Habit counseling (for example, pacifier use and thumb sucking)</li>
                  <li> Repair of tooth cavities or defects</li>
                  <li> Management of gum diseases and conditions including ulcers, short frenulae, mucoceles, and pediatric periodontal disease</li>
                  <li> Care for dental injuries (for example, fractured, displaced, or knocked-out teeth)</li>
                </ul>
              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={kids4} alt="kids" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={kids2} alt="kids2" />
              </div>
              {/* <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd3} alt="cosmetic dentistry" />
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd4} alt="cosmetic dentistry" />
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd5} alt="cosmetic dentistry" />
            </div> */}
            </div>

          </div>
        )
        break;
      case 7:
        toReturn = (
          <div>
            <div className='text-center'>
              <p className="mb-8 mb-12 2xl:mb-12 text-gray-400 text-xl text-center">
                A denture is a removable replacement for missing teeth and surrounding tissues. Two types of dentures are available -- complete and partial dentures. Complete dentures are used when all the teeth are missing, while partial dentures are used when some natural teeth remain.
                The Benefits of Wearing Dentures
                <ul>
                  <li>Modern dentures are designed to be comfortable, as well as perform the functions of natural teeth.</li>
                  <li>You no longer have to have restrictions on what you can eat. </li>
                  <li>Dentures restore your smile and eliminate any social awkwardness you may have felt about the gaps in your teeth.</li>
                </ul>
              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full ">
                <img className="w-full h-96" src={dent1} alt="dentures" />
              </div>
              <div className="w-full ">
                <img className="w-full h-100" src={dent2} alt="dentures" />
              </div>
              <div className="w-full ">
                <img className="w-full h-full" src={dent3} alt="dentures" />
              </div>
              {/* <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd4} alt="cosmetic dentistry" />
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd5} alt="cosmetic dentistry" />
            </div>  */}            </div>

          </div>)
        break;
      case 8:
        toReturn = (
          <div>
            <div className='text-center'>
              <p className="mb-8 mb-12 2xl:mb-12 text-gray-400 text-xl text-center">
                Teeth whitening is a simple process. Whitening products contain one of two tooth bleaches (hydrogen peroxide or carbamide peroxide).
                These bleaches break stains into smaller pieces, which makes the color less concentrated and your teeth brighter.
              </p>
            </div>
            <div className="flex flex-wrap -m-4">
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={white1} alt="teeth Whitening" />
              </div>
              <div className="w-full lg:w-1/2 p-4">
                <img className="w-full h-96 object-cover" src={white2} alt="Teeth Whitening" />
              </div>
              {/* <div className="w-full lg:w-1/1 p-4">
              <img className="w-full h-96 object-cover" src={dent3} alt="dentures" />
            </div> */}
              {/* <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd4} alt="cosmetic dentistry" />
            </div>
            <div className="w-full lg:w-1/2 p-4">
              <img className="w-full h-96 object-cover" src={cd5} alt="cosmetic dentistry" />
            </div>  */}
            </div>
          </div>
        )
        break;

      default:

        break;
    }
    console.log('end switch toReturn: ', toReturn);
    return toReturn
  }
  return (
    <div className="antialiased bg-body text-body font-body">
      <div className="" >

        {/* <p className="mb-2 text-base text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea sit eaque totam aliquid veritatis assumenda temporibus harum unde!</p> */}

        <section className="py-20 2xl:py-25">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="max-w-xl mx-auto mb-12 lg:mb-16 text-center">
                <span className="text-lg text-blue-10 font-bold text-3xl">TREATMENTS</span>
                <h2 className="mt-5 mb-10 text-2xl font-bold font-heading">At Daily Dentist, we provide the highest quality of dental health care. We offer:</h2>
              </div>
              <div className="lg:flex justify-center mb-16 mx-auto pb-6 border-b border-gray-50">
                <button onClick={handleChange} value={1} className={display === 1 ? "w-full md:w-auto px-8 py-4 text-lg font-bold shadow rounded-lg" : "w-full md:w-auto px-8 py-4 text-lg rounded-lg"}>Consultations</button>
                <button onClick={handleChange} value={2} className={display === 2 ? "w-full md:w-auto px-8 py-4 text-lg font-bold shadow rounded-lg" : "w-full md:w-auto px-8 py-4 text-lg rounded-lg"}>Xrays</button>
                <button onClick={handleChange} value={3} className={display === 3 ? "w-full md:w-auto px-8 py-4 text-lg font-bold shadow rounded-lg" : "w-full md:w-auto px-8 py-4 text-lg rounded-lg"}>General Dentistry  </button>
                <button onClick={handleChange} value={4} className={display === 4 ? "w-full md:w-auto px-8 py-4 text-lg font-bold shadow rounded-lg" : "w-full md:w-auto px-8 py-4 text-lg rounded-lg"}>Cosmetic Dentistry</button>
                <button onClick={handleChange} value={6} className={display === 6 ? "w-full md:w-auto px-8 py-4 text-lg font-bold shadow rounded-lg" : "w-full md:w-auto px-8 py-4 text-lg rounded-lg"}>Paedaetric Dentistry</button>
                <button onClick={handleChange} value={5} className={display === 5 ? "w-full md:w-auto px-8 py-4 text-lg font-bold shadow rounded-lg" : "w-full md:w-auto px-8 py-4 text-lg rounded-lg"}>Mouthguards</button>
                <button onClick={handleChange} value={7} className={display === 7 ? "w-full md:w-auto px-8 py-4 text-lg font-bold shadow rounded-lg" : "w-full md:w-auto px-8 py-4 text-lg rounded-lg"}>Dentures</button>
                <button onClick={handleChange} value={8} className={display === 8 ? "w-full md:w-auto px-8 py-4 text-lg font-bold shadow rounded-lg" : "w-full md:w-auto px-8 py-4 text-lg rounded-lg"}>Teeth Whitening</button>

              </div>
              {/* <div className="flex flex-wrap mb-12 lg:mb-16 -m-6">
                <div className="w-full md:w-1/2 p-6">
                  <div className="flex flex-wrap -m-6">
                    <div className="w-full lg:w-1/2 p-6">
                      <img className="w-full h-64 object-cover object-top" src={extr1} alt="Extractions" />
                    </div>
                    {/* <div className="w-full lg:w-1/2 p-6">
                      <img className="w-full h-64 object-cover" src="https://images.unsplash.com/photo-1618172193622-ae2d025f4032?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=959&amp;q=80" alt=""/>
                      </div>
                    <div className="w-full p-6">
                      <img className="w-full h-112 object-cover" src="https://images.unsplash.com/photo-1617791160505-6f00504e3519?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1100&amp;q=80" alt=""/>

                      </div> * /}
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-6">
                  <div className="flex flex-wrap -m-6">
                    <div className="w-full p-6">
                      {/* <img className="w-full h-112 object-cover" src="https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=967&amp;q=80" alt=""/> * /}
                      <img className="w-full h-112 object-cover" src={extr2} alt="Extractions" />
                    </div>
                    {/* <div className="w-full lg:w-1/2 p-6">
                      <img className="w-full h-64 object-cover" src="https://images.unsplash.com/photo-1618172193763-c511deb635ca?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=959&amp;q=80" alt=""/>

                      </div>
                    <div className="w-full lg:w-1/2 p-6">
                      <img className="w-full h-64 object-cover" src="https://images.unsplash.com/photo-1617791160505-6f00504e3519?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1100&amp;q=80" alt=""/>
                      </div> * /}
                  </div>
                </div>
              </div> */}
              {toDisplay(display)}

              {/* <div className="flex flex-wrap -m-4"> */}
              {/* <div class="w-full lg:w-1/2 p-4">
                  <img class="w-full h-96 object-cover" src={extr2} alt="extractions" />
                </div>
                <div class="w-full lg:w-1/2 p-4">
                  <img class="w-full h-96 object-cover" src={bridge} alt="" />
                </div>
                <div class="w-full lg:w-1/3 p-4">
                  <img class="w-full h-96 object-cover" src="https://images.unsplash.com/photo-1618172193763-c511deb635ca?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=959&amp;q=80" alt="" />
                </div>
                <div class="w-full lg:w-1/3 p-4">
                  <img class="w-full h-96 object-cover" src="https://images.unsplash.com/photo-1618172193622-ae2d025f4032?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=959&amp;q=80" alt="" />
                </div>
                <div class="w-full lg:w-1/3 p-4">
                  <img class="w-full h-96 object-cover" src="https://images.unsplash.com/photo-1618005198920-f0cb6201c115?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1050&amp;q=80" alt="" />
                </div> */}
              {/* </div> */}
              {/* <div className="mt-20 text-center">
                <a className="inline-block py-4 px-12 text-white font-bold bg-blue-10 hover:bg-blue-600 rounded-full" href="#">See all</a>
              </div> */}
            </div>
          </div>
        </section>
      </div>
      <Helmet>
        <base />
        <title>Treatments</title>
        <meta name="description" content="View all Dentistry treaments that we offer" />
        {/* <link rel="canonical" href="somelink" /> */}

        {/* <script src="../assets/js/main2.js" type="text/javascript" /> */}

      </Helmet>
    </div >

  );
}
