
import { React } from 'react';
import { ReactComponent as DropboxLogo } from '../assets/zospace-assets/logos/dropbox-gray.svg';
import { ReactComponent as DDLogo } from '../assets/zospace-assets/logos/ddnew3.svg';
import DDLogo3 from '../assets/zospace-assets/logos/ddnew3.svg';
import DDLogo2 from '../assets/zospace-assets/logos/ddnew4.svg';

import { ReactComponent as LineTop } from '../assets/zospace-assets/lines/line-top.svg';

import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContactUs from './../pages/contactus.component';
import ContactDetails from './contactdetails.component';

export default function Navbar() {


    useEffect(() => {
        console.log("using effect");

        // open
        const burger = document.querySelectorAll('.navbar-burger');
        const menu = document.querySelectorAll('.navbar-menu');

        if (burger.length && menu.length) {
            for (var i = 0; i < burger.length; i++) {
                burger[i].addEventListener('click', function () {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }

        // close
        const close = document.querySelectorAll('.navbar-close');
        const backdrop = document.querySelectorAll('.navbar-backdrop');

        if (close.length) {
            for (var t = 0; t < close.length; t++) {
                close[t].addEventListener('click', function () {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }

        if (backdrop.length) {
            for (var z = 0; z < backdrop.length; z++) {
                backdrop[z].addEventListener('click', function () {
                    for (var j = 0; j < menu.length; j++) {
                        menu[j].classList.toggle('hidden');
                    }
                });
            }
        }
    }, []);

    return (
        <section>

            <nav>
                <div className="flex py-8 px-4 lg:px-8 justify-between items-center">
                    {/* <a className="text-2xl text-gray-900 font-bold" href="#"> */}
                    <Link className="text-2xl text-blue-10 font-bold" to="/Home" onClick={() => window.scrollTo(0, 0)}>

                        {/* <img className="h-7" src="zospace-assets/logos/zospace-dark-logo.svg" alt="" width="auto" /> */}
                        {/* <DDLogo className="h-20"  length="auto" /> */}
                        <img className="h-22 lg:w-2/5" src={DDLogo2} alt="dailyDentist" width="auto" />
                        <div className=" w-full md:w-1/3 px-4 mb-4 md:mb-0 ">
                        </div>
                    </Link>
                    {/* </a> */}
                    <div className="lg:hidden ml-auto">
                        <button className="navbar-burger flex items-center p-3 hover:bg-gray-50 text-gray-900 rounded">
                            <svg className="w-10 h-3" width="39" height="13" viewBox="0 0 39 13" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="39" height="2" rx="1" fill="currentColor"></rect><rect x="19" y="11" width="20" height="2" rx="1" fill="currentColor"></rect><title>Mobile menu</title></svg></button>
                    </div>
                    <div className="hidden lg:block ml-auto mr-16">
                        <ul className="flex items-center space-x-16 text-lg font-bold">
                            <li>
                                <Link className="hover:underline text-blue-10" to="/Home" onClick={() => window.scrollTo(0, 0)}>
                                    Home
                                </Link>

                                {/* <a className="hover:underline" href="#">Home</a> */}
                            </li>
                            <li>
                                {/* <a className="hover:underline" href="#">Treatments</a> */}
                                <Link className="hover:underline text-blue-10" to="/treatments" onClick={() => window.scrollTo(0, 0)}>
                                    Treatments
                                </Link>
                            </li>
                            <li>
                                {/* <a className="hover:underline" href="#">Partners</a> */}
                                <Link className="hover:underline text-blue-10" to="/partners" onClick={() => window.scrollTo(0, 0)}>
                                    Partners
                                </Link>
                            </li>
                            {/* <li>
                                {/* <a className="hover:underline" href="#">About Us</a> * /}
                                <Link className="hover:underline text-blue-10" to="/aboutus" onClick={() => window.scrollTo(0, 0)} >
                                    About Us
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                    <div className="hidden lg:block">
                        {/* <a className="inline-block px-12 py-4 text-white font-bold bg-black border border-black hover:bg-white hover:text-black rounded-full transition duration-200" href="#"> */}
                        <Link className="inline-block px-12 py-4 text-white font-bold bg-blue-10 border border-blue-10 hover:bg-white hover:text-black rounded-full transition duration-200" to="/contactus" >
                            Contact Us
                        </Link>
                        {/* </a> */}
                    </div>
                </div>
            </nav>

            <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
                <div className="navbar-backdrop fixed inset-0 bg-blue-600 opacity-80"></div>
                <nav className="relative flex flex-col py-8 w-full h-full bg-gray-800 overflow-y-auto">
                    <div className="flex items-center mb-16 pr-6">
                        {/* <a className="ml-10 mr-auto text-2xl text-white font-bold"> */}
                        <Link className="ml-10 mr-auto text-2xl text-white font-bold" to="/Home" onClick={() => window.scrollTo(0, 0)}>

                            {/* <img className="h-7" src="zospace-assets/logos/zospace-logo.svg" alt="" width="auto" /> */}
                            <img className="navbar-close h-22" src={DDLogo2} alt="dailyDentist" width="auto" />
                        </Link>
                        {/* </a> */}
                    </div>
                    <div>
                        {/* <ul><li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Home</a></li>
                            <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Treatments</a></li>
                            <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Partners</a></li>
                            <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Contact</a></li>
                        </ul> */}
                        <ul>
                            <li className="mb-1 px-10">
                                <Link className="navbar-close block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" to="/Home" onClick={() => window.scrollTo(0, 0)}>
                                    Home
                                </Link>

                                {/* <a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Home</a> */}
                            </li>
                            <li className="mb-1 px-10">
                                {/* <a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Treatments</a> */}
                                <Link className="navbar-close block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" to="/treatments" onClick={() => window.scrollTo(0, 0)}>
                                    Treatments
                                </Link>
                            </li>
                            <li className="mb-1 px-10">
                                {/* <a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Partners</a> */}
                                <Link className="navbar-close block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" to="/partners" onClick={() => window.scrollTo(0, 0)}>
                                    Partners
                                </Link>
                            </li>
                            <li className="mb-1 px-10">
                                {/* <a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">About Us</a> */}
                                <Link className="navbar-close block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" to="/contactus" onClick={() => window.scrollTo(0, 0)}>
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-auto px-10">
                        {/* <div className="pt-6">
                            <a className="block mb-4 py-4 px-12 text-white text-center font-bold hover:bg-white hover:text-gray-800 border border-gray-50 rounded-full transition duration-200" href="#">Sign in</a><a className="block py-4 px-12 text-white text-center font-bold bg-blue-10 hover:bg-blue-600 rounded-full transition duration-200" href="#">Sign up</a>
                        </div> */}
                        <p className="mt-6 mb-4 text-lg text-center text-white">
                            <span >2021 © Daily Dentist. All rights reserved.</span>
                        </p>
                    </div>
                </nav>
            </div>
            <div className="container mx-auto px-4 ">
                <div className="max-w-6xl mx-auto ">
                    <ContactDetails />

                </div>
            </div>

        </section>



    )
}