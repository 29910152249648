import React from "react";


export default function ContactDetails() {

    return (
      <div className="flex flex-wrap -mx-4 -mb-4 md:mb-0">
        <div className="lg:text-center w-full md:w-1/4 left-20 px-4 mb-4 md:mb-0">
          {/* <div className="hidden lg:block"> Find us at: </div> */}
          <p
                className="items-center justify-center text-center text-sm font-bold hover:underline cursor-pointer"
                // window.open("https://maps.google.com?q=" + -29.831390835220187 + "," + 30.984118159949738);
                //     onClick={() => {
                //     window.open("https://www.google.com/maps/dir//473+Randles+Rd,+Sparks,+Berea,+4091/@-29.8317596,30.9830534,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1ef700b450084e1f:0xe889fb2eedcc24bf!2m2!1d30.9841144!2d-29.8314071");
                // }}
              >
          DR G.A. BARAKZAI Practice No: 0917001 DP No:0118150
                </p>
        </div>
        <div className="lg:text-center w-full md:w-1/4 left-20 px-4 mb-4 md:mb-0">
          <div className="hidden lg:block"> Find us at: </div>
          <div className="flex hover:underline cursor-pointer">
            {/* <i className="flaticon-navigation-arrow" /> */}
            <span className="flex-shrink-0 flex items-center justify-center w-8 h-8 ml-6 mr-6 bg-blue-10 rounded-lg">
              <svg
                className="w-5 h-5"
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5 11C16.0185 11 14 13.0962 14 15.6729C14 19.2285 17.8951 22.6871 18.0612 22.8339C18.3123 23.0554 18.6877 23.0554 18.9388 22.8339C19.1049 22.6885 23 19.2298 23 15.6729C23 13.0962 20.9815 11 18.5 11ZM18.5 17.4618C17.5077 17.4618 16.7003 16.6338 16.7003 15.6161C16.7003 14.5985 17.5077 13.7705 18.5 13.7705C19.4923 13.7705 20.2997 14.5985 20.2997 15.6161C20.2997 16.6338 19.4923 17.4618 18.5 17.4618Z"
                  fill="white"
                ></path>
                <path
                  d="M9.01758 0C4.04824 0 0 4.04035 0 9C0 13.9597 4.04824 18 9.01758 18C9.90081 18 10.7583 17.8745 11.5604 17.6315C10.1794 14.5374 10.8571 11.7539 12.7236 9.9193C12.6912 9.9193 12.2597 9.73436 12.2272 9.72086C8.69567 8.37903 5.3927 12.6232 8.35888 16.156C6.02299 15.94 4.00361 14.6171 2.84987 12.7096C4.09829 12.4153 5.00721 11.3057 5.02344 9.9922C5.04373 9.16199 5.60505 8.59232 6.30433 8.43573C9.41794 7.77021 9.7128 3.90535 8.51307 1.83051C9.34355 1.78461 10.0442 1.80756 11.0383 2.10049C10.7881 4.61407 12.1501 7.66087 15.1596 7.37468L16.0158 7.27614C16.0875 7.57312 16.1335 7.87011 16.1605 8.18464C16.7272 8.08745 17.4468 8.06855 18 8.17519C17.5739 3.60027 13.7056 0 9.01758 0Z"
                  fill="white"
                ></path>
              </svg>
            </span>
            <div
              // className="items-center justify-center text-center text-sm font-bold hover:underline cursor-pointer"
              className="text-sm font-bold hover:underline cursor-pointer"
              onClick={() => {
                // window.open("https://maps.google.com?q=" + -29.831390835220187 + "," + 30.984118159949738);
                window.open(
                  "https://www.google.com/maps/dir//473+Randles+Rd,+Sparks,+Berea,+4091/@-29.8317596,30.9830534,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1ef700b450084e1f:0xe889fb2eedcc24bf!2m2!1d30.9841144!2d-29.8314071"
                );
              }}
            >
              {/* <p className="text-sm font-bold hover:underline cursor-pointer" onClick={() => {
                    // window.open("https://maps.google.com?q=" + -29.831390835220187 + "," + 30.984118159949738);
                    window.open("https://www.google.com/maps/dir//473+Randles+Rd,+Sparks,+Berea,+4091/@-29.8317596,30.9830534,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1ef700b450084e1f:0xe889fb2eedcc24bf!2m2!1d30.9841144!2d-29.8314071");
                }}> */}
              {/* <p className="items-center justify-center text-center text-sm font-bold hover:underline cursor-pointer"> */}
              1st Floor, 473 Randles Road, Overport, Durban, 4091
              {/* </p>  */}
            </div>
          </div>
        </div>

        <div className="lg:text-center w-full md:w-1/4 left-20 px-4 mb-4 md:mb-0">
          <div className="hidden lg:block text-left ml-20">
            {" "}
            Contact Details:{" "}
          </div>

          <div className="flex hover:underline cursor-pointer">
            <span className="flex-shrink-0 flex items-center justify-center w-8 h-8 ml-6 mr-6 bg-blue-10 rounded-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="white"
              >
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
            </span>
            <div>
              <p
                className="items-center justify-center text-center text-sm font-bold hover:underline cursor-pointer"
                // window.open("https://maps.google.com?q=" + -29.831390835220187 + "," + 30.984118159949738);
                //     onClick={() => {
                //     window.open("https://www.google.com/maps/dir//473+Randles+Rd,+Sparks,+Berea,+4091/@-29.8317596,30.9830534,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1ef700b450084e1f:0xe889fb2eedcc24bf!2m2!1d30.9841144!2d-29.8314071");
                // }}
              >
                <a href="tel:0685767146">+27 68 5767 146</a>
                {/* +27 68 5767 146 */}
              </p>
            </div>
          </div>
          {/* <span className="absolute flex-shrink-0 flex items-center justify-center w-8 h-8 ml-6 mr-6 bg-blue-10 rounded-lg">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="white">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
        </span> */}
          {/* <p className="items-center justify-center text-center text-sm font-bold hover:underline cursor-pointer" onClick={() => {
                    // window.open("https://maps.google.com?q=" + -29.831390835220187 + "," + 30.984118159949738);
                    window.open("https://www.google.com/maps/dir//473+Randles+Rd,+Sparks,+Berea,+4091/@-29.8317596,30.9830534,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1ef700b450084e1f:0xe889fb2eedcc24bf!2m2!1d30.9841144!2d-29.8314071");
                }}> */}

          {/* <br /> */}
          {/* +27 68 5767 146 */}
          {/* </p> */}
        </div>
        <div className=" w-full md:w-1/4 px-4 mb-4 md:mb-0 ">
          {/* Operating Hours: */}
          <div className="hidden lg:block text-left ml-20">
            Operating Hours:{" "}
          </div>

          <div className="flex hover:underline cursor-pointer">
            <span className="flex-shrink-0 flex items-center justify-center w-8 h-8 ml-6 mr-6 bg-blue-10 rounded-lg">
              {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="white">
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </span>
            <div>
              <p
                className="text-sm items-center justify-center font-bold hover:underline cursor-pointer"
                // onClick={() => {
                // window.open("https://www.google.com/maps/dir//473+Randles+Rd,+Sparks,+Berea,+4091/@-29.8317596,30.9830534,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1ef700b450084e1f:0xe889fb2eedcc24bf!2m2!1d30.9841144!2d-29.8314071");
                // }}
              >
                8:00 – 17:00 Monday to Friday
                <br />
                8:00 – 13:00 Saturday
              </p>
            </div>
          </div>
        </div>
      </div>
    );
}


