import { React } from 'react';
import { ReactComponent as DropboxLogo } from '../assets/zospace-assets/logos/dropbox-gray.svg';
import { ReactComponent as DDLogo } from '../assets/zospace-assets/logos/ddnew3.svg';
import DDLogo2 from '../assets/zospace-assets/logos/ddnew3.svg';
import patientfront from '../assets/zospace-assets/images/woman-patient-dentist.jpg'
import jawModel from '../assets/zospace-assets/images/plastic-jaw-dentistry-clinic.jpg'
import dentisttech from '../assets/zospace-assets/images/dentisttech.jpg'
import { ReactComponent as LineTop } from '../assets/zospace-assets/lines/line-top.svg';

import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function Homepage() {

  // useEffect(() => {
  //   console.log("using effect");

  //   // open
  //   const burger = document.querySelectorAll('.navbar-burger');
  //   console.log('main js runs 1', burger);
  //   const menu = document.querySelectorAll('.navbar-menu');

  //   if (burger.length && menu.length) {
  //     for (var i = 0; i < burger.length; i++) {
  //       burger[i].addEventListener('click', function () {
  //         for (var j = 0; j < menu.length; j++) {
  //           menu[j].classList.toggle('hidden');
  //         }
  //       });
  //     }
  //   }

  //   console.log('main js runs 2');
  //   // close
  //   const close = document.querySelectorAll('.navbar-close');
  //   const backdrop = document.querySelectorAll('.navbar-backdrop');

  //   console.log('main js runs 3', close);
  //   if (close.length) {
  //     for (var i = 0; i < close.length; i++) {
  //       close[i].addEventListener('click', function () {
  //         for (var j = 0; j < menu.length; j++) {
  //           menu[j].classList.toggle('hidden');
  //         }
  //       });
  //     }
  //   }

  //   if (backdrop.length) {
  //     for (var i = 0; i < backdrop.length; i++) {
  //       backdrop[i].addEventListener('click', function () {
  //         for (var j = 0; j < menu.length; j++) {
  //           menu[j].classList.toggle('hidden');
  //         }
  //       });
  //     }
  //   }
  // }, []);
  return (


    <div className="antialiased bg-body text-body font-body">

      <div className="">

        <section className="relative overflow-hidden">
          {/* <nav>
            <div className="flex py-8 px-4 lg:px-8 justify-between items-center">
              <a className="text-2xl text-gray-900 font-bold" href="#">
                {/* <img className="h-7" src="zospace-assets/logos/zospace-dark-logo.svg" alt="" width="auto" /> *  / }
                {/* <DDLogo className="h-20"  length="auto" /> * / }
                <img className="h-20" src={DDLogo2} alt="dailyDentist" width="auto" />
              </a>
              <div className="lg:hidden ml-auto">
                <button className="navbar-burger flex items-center p-3 hover:bg-gray-50 text-gray-900 rounded">
                  <svg className="w-10 h-3" width="39" height="13" viewBox="0 0 39 13" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="39" height="2" rx="1" fill="currentColor"></rect><rect x="19" y="11" width="20" height="2" rx="1" fill="currentColor"></rect><title>Mobile menu</title></svg></button>
              </div>
              <div className="hidden lg:block ml-auto mr-16">
                <ul className="flex items-center space-x-16 text-lg font-bold"><li><a className="hover:underline" href="#">Home</a></li>
                  <li><a className="hover:underline" href="#">Treatments</a></li>
                  <li><a className="hover:underline" href="#">Partners</a></li>
                  <li><a className="hover:underline" href="#">Contact</a></li>
                </ul></div>
              <div className="hidden lg:block"
              ><a className="inline-block px-12 py-4 text-white font-bold bg-black border border-black hover:bg-white hover:text-black rounded-full transition duration-200" href="#">
                  Sign Up
                </a>
              </div>
            </div>
          </nav> */}
          {/* <img className="hidden lg:block absolute top-0 right-0 -mr-20 mt-16" src="zospace-assets/lines/line-top.svg" alt="" /> */}
          {/* <LineTop className="hidden lg:block absolute top-0 right-0 -mr-20 mt-16" /> */}
          <div className="relative lg:absolute h-128 w-full lg:w-1/2 mt-6 px-4">
            {/* <img className="lg:absolute left-0 top-0 lg:-ml-48 w-full h-96 lg:h-128 2xl:h-144 object-cover" src="https://images.pexels.com/photos/7148031/pexels-photo-7148031.jpeg?q=80&amp;fm=jpg&amp;crop=faces&amp;cs=tinysrgb&amp;fit=crop&amp;h=450&amp;w=940" alt="" /> */}
            {/* <img className="lg:absolute left-0 top-0 lg:-ml-48 w-full h-96 lg:h-128 2xl:h-144 object-cover" src="../assets/zospace-assets/images/woman-patient-dentist.jpg" alt="dentist extraction" />*/}
            <img className="lg:absolute left-0 top-0 lg:-ml-48 w-full h-96 lg:h-128 2xl:h-144 object-cover" src={patientfront} alt="dentist extraction" />
            <div className="absolute bottom-0 left-0 w-52 h-92 ml-10 mb-6">
              {/* <img src={jawModel} alt="Dentist Jaw Model" /> */}
              {/* <a className="absolute bottom-0 left-0 ml-6 mb-6 pt-3 pb-4 px-6 flex items-center hover:text-blue-10 bg-white border-t-4 border-blue-10 rounded-t rounded-b-2xl" href="#"> */}
              {/* <svg className="h-3 w-2 mr-2 text-blue-10" width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.66236 5.29284L2.44137 0.349369C2.10789 0.120911 1.77189 0 1.4926 0C0.952657 0 0.618652 0.433344 0.618652 1.15871V11.843C0.618652 12.5675 0.952237 13 1.49092 13C1.77062 13 2.10126 12.879 2.43548 12.6499L9.65984 7.70653C10.1244 7.3881 10.3817 6.9596 10.3817 6.49942C10.3818 6.03956 10.1275 5.61116 9.66236 5.29284Z" fill="currentColor">
                  </path>
                </svg> */}
              {/* <p className="text-lg font-bold">Play video</p> */}
              {/* </a> */}
            </div>
          </div>
          <div className="container px-4 mx-auto pt-12 lg:pt-16">
            <div className="flex flex-wrap -mx-4 mb-14 lg:mb-22 2xl:mb-20">
              <div className="w-full lg:w-1/2 lg:ml-auto px-4 ">
                <span className="text-lg text-blue-10 font-bold text-4xl text-4xl">Welcome to DAILY DENTIST</span>
                <h2 className="max-w-sm my-8 lg:my-12 text-3xl 2xl:text-4xl font-bold font-heading">Our Mission</h2>
                <p className="mb-16 pb-10 mb-20 2xl:mb-24 text-gray-400 text-xl border-b border-gray-500 ">To provide pain free Dentistry.<br />
                  Our mission as individual dental professionals is to make a difference in a patient’s life by striving to provide pain free dentistry.
                  We care deeply about our patients and what we do to help them maintain dental health for a lifetime.
                  We will continue to grow in our professional skills through continuing education and dedication to be the best.
                  Our mission as a dental team is to work together as a unit to provide the best dental care available for all ages, caring for them as we would family members.
                  <br /> We treat our patients with patience and kindness. We are committed as a team to avoid complacency, choosing instead to stay on the cutting edge of dentistry through continuing education and use of the latest and best treatment modalities, materials, sterilization techniques, and technology.
                </p>
          
                <Link className="px-12 py-6 bg-blue-10 hover:bg-blue-600 rounded-full text-lg text-white font-bold transition duration-200" to="/treatments" onClick={() => window.scrollTo(0, 0)}>Learn More</Link>
              </div>
            
            </div>
            {/* <div className="max-w-6xl mx-auto -mb-12 flex flex-wrap items-center justify-center">
              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-2 mb-12">
                <img className="h-8 mx-auto" src="assets/zospace-assets/logos/amazon-gray.svg" alt="" /></div>
              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-2 mb-12">
                {/* <img className="h-8 mx-auto" src={require("../assets/zospace-assets/logos/dropbox-gray.svg")} alt="nothing" /> * /}
                <DropboxLogo className="h-8 mx-auto" />
              </div>
              
              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-2 mb-12">
                <img className="h-8 mx-auto" src="zospace-assets/logos/stripe-gray.svg" alt="" /></div>
              <div className="w-1/2 md:w-1/3 lg:w-1/5 px-2 mb-12">
                <img className="h-8 mx-auto" src="zospace-assets/logos/slack-gray.svg" alt="" /></div>
            </div> */}
          </div>
          {/* <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
            <div className="navbar-backdrop fixed inset-0 bg-blue-600 opacity-80"></div>
            <nav className="relative flex flex-col py-8 w-full h-full bg-gray-800 overflow-y-auto">
              <div className="flex items-center mb-16 pr-6">
                <a className="ml-10 mr-auto text-2xl text-white font-bold" href="#">
                  {/* <img className="h-7" src="zospace-assets/logos/zospace-logo.svg" alt="" width="auto" /> * /}
                  <img className="h-20" src={DDLogo2} alt="dailyDentist" width="auto" />
                </a>
              </div>
              <div>
                <ul><li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Home</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Treatments</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Partners</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-white hover:bg-gray-500 rounded-xl" href="#">Contact</a></li>
                </ul></div>
              <div className="mt-auto px-10">
                <div className="pt-6"><a className="block mb-4 py-4 px-12 text-white text-center font-bold hover:bg-white hover:text-gray-800 border border-gray-50 rounded-full transition duration-200" href="#">Sign in</a><a className="block py-4 px-12 text-white text-center font-bold bg-blue-10 hover:bg-blue-600 rounded-full transition duration-200" href="#">Sign up</a></div>
                <p className="mt-6 mb-4 text-lg text-center text-white">
                  <span>2021 © Daily Dentist. All rights reserved.</span>
                </p>
              </div>
            </nav>
          </div> */}
        </section>

        <section className="relative pb-10 lg:pb-10 lg:pt-10 2xl:py-40 overflow-hidden">
          <div className="relative w-full lg:w-3/5 lg:ml-auto mb-20 lg:mb-0">
            <img className="hidden lg:block lg:absolute right-0 top-0 w-full h-128 object-cover" src={dentisttech} alt="Dental technology" />
            <img className="lg:hidden w-full h-96 object-cover" src={dentisttech} alt="Dental technology" />

          </div>
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-2/5 px-4">
                <div className="max-w-lg">
                  <h2 className="mb-12 lg:mb-20 text-5xl lg:text-4xl lg:text-center font-bold font-heading">Our Vision</h2>
                  <div className="flex items-center mb-8 pb-14 border-b border-gray-500">
                    {/* <div className="max-w-sm"> */}
                    <div className="max-w">
                      <p className="text-lg text-gray-400 mb-12">We at Daily Dentist will continually update ourselves regarding the best and most current materials and technology available in dentistry and infection control.
                        <br />
                        We will treat the patient's dental needs with the highest level of care, skill, judgment and comfort.
                        <br />We will adapt, grow and evolve with our profession and society.
                        <br /> We will appreciate, communicate and empathize with our patients and our fellow team members so that we may enhance the lives of every person involved in achieving our mission.</p>
                    </div>
                  </div>
                  {/* <a className="flex items-center justify-end font-bold text-gray-200" href="#"> */}

                  <span className="ml-5">
                    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.7387 1.47293L18.7387 10.93L17.5565 10.93L17.5565 2.89739L0.835337 19.6245L0.00193592 18.7911L16.7231 2.064L9.28164 2.064L9.28164 0.881867L18.1476 0.881866C18.4741 0.881866 18.7387 1.14648 18.7387 1.47293Z" fill="white"></path></svg>
                  </span>
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>
      {/* <Helmet>

        {/* <script src="../assets/js/main2.js" /> * / }
        {/* <script src="https://codepen.io/shshaw/pen/QmZYMG.js" type="text/javascript" /> * /}

      </Helmet>

*/}
      <Helmet>
        <base />
        <title>DailyDentist- Root Canal Specialist | Dentist in Durban | Pediatric Dentist</title>
        <meta name="description" content="Daily Dentist 473 Randles Road Overport, Durban. For all your mouth, tooth and dentistry needs. 
         Looking for a Root Canal Specialist or Dentist in Durban ? DailyDentist provides high quality and pain free Dentistry  in a relaxed and caring environment located in Durban" />
        {/* <link rel="canonical" href="somelink" /> */}

        {/* <script src="../assets/js/main2.js" type="text/javascript" /> */}

      </Helmet>

    </div>
    // {/* </html> */ }
  );
}

