import { React } from 'react';
import momentum from '../assets/zospace-assets/images/momentum.png'
import medihelp  from '../assets/zospace-assets/images/medihelp.png'
import medscheme  from '../assets/zospace-assets/images/medscheme.png'
import discovery from '../assets/zospace-assets/images/discovery.png'
import gems from '../assets/zospace-assets/images/gems.png'
import denis from '../assets/zospace-assets/images/denis.png'

import { Helmet } from "react-helmet";
import { useEffect } from 'react';

export default function PartnersPage() {
  return (

    <div className="antialiased bg-body text-body font-body">
      <div className="">

        <section className="py-20 bg-blue-50"><div className="container px-4 mx-auto">
          <div className="max-w-8xl mx-auto mb-20 text-center">
            <span className="text-lg text-blue-10 font-bold text-3xl">Our Partners</span>
            <h2 className="mt-8 text-2xl lg:text-2xl font-bold font-heading text-gray-800">We work closely with all major Medical Aids. <br/> We will contact your medical aid for you to find out what benefits are available on your membership. </h2>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="w-full md:w-1/2 lg:w-1/5 p-4">
              <div className="flex w-full h-32 items-center justify-center bg-white rounded-lg">
                <img className="h-16" src={discovery} alt="discovery" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/5 p-4">
              <div className="flex w-full h-32 items-center justify-center bg-white rounded-lg">
                <img className="h-16" src={momentum} alt="momentum" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/5 p-4">
              <div className="flex w-full h-32 items-center justify-center bg-white rounded-lg">
                <img className="h-16" src={denis} alt="denis" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/5 p-4">
              <div className="flex w-full h-32 items-center justify-center bg-white rounded-lg">
                <img className="h-16" src={gems} alt="gems" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/5 p-4">
              <div className="flex w-full h-32 items-center justify-center bg-white rounded-lg">
                <img className="h-16" src={medihelp} alt="medihelp" />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/5 p-4">
              <div className="flex w-full h-32 items-center justify-center bg-white rounded-lg">
                <img className="h-16" src={medscheme} alt="medscheme" />
              </div>
            </div>
          </div>
        </div>
        </section>
      </div>
      <section className="relative py-5 2xl:py-10">
        <div className="container px-4 pb-5 mx-auto border-b border-gray-100">
          <div className="mb-14 text-center">
            {/* <span className="text-lg text-blue-10 font-bold">Check of details</span> */}
            <h2 className="mt-8 text-5xl font-bold font-heading">Some of the Medical Aids we work with</h2>
          </div>
          <div className="pt-20 border-t border-gray-100 overflow-x-auto overflow-y-hidden">
            <table className="table-auto w-full mb-10">
              <thead>
                <tr className="text-lg text-left font-bold"><th className="pb-6">Medical Aid</th>
                  <th className="pb-6 pr-8">Policies</th>
                  {/* <th className="pb-6 pr-8">Dental Risk Company (DRC)</th> */}
                  {/* <th className="pb-6 pr-8">Denis</th> */}
                  {/* <th className="pb-6 pr-8">Momentum</th> */}
                  {/* <th className="pb-6 pr-8">GEMS</th> */}
                </tr>
              </thead>
              <tbody>
                <tr className="text-xl bg-blue-50">
                  <td className="py-5 pl-10 text-left">Medscheme</td>
                  <td>
                    AECI Medical Aid Society,<br/>
                    Barloworld Medical Scheme,<br/>
                    Bonitas,<br/>
                    Fedhealth,<br/>
                    Government Employees Medical Scheme (GEMS),<br/>
                    Horizon Medical Scheme,<br/>
                    Hosmed Medical Scheme,<br/>
                    MBMed Medical Aid Fund,<br/>
                    Medshield,<br/>
                    Namibia Health Plan,<br/>
                    Parmed Medical Aid Scheme,<br/>
                    SABC Medical Scheme,<br/>
                    SAMWUMED,<br/>
                    South African Police Service (POLMED)

                  </td>
                  {/* <td>20</td>
                  <td>200+</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td> */}
                </tr>
                <tr className="text-xl">
                  <td className="py-5 pl-10 text-left">Dental Risk Company (DRC) </td>
                  <td>
                    Medihelp,<br/>
                    Discovery Administered Clients,<br/>
                    Moto Health Care,<br/>
                    Medicall Health,<br/>
                    Sizwe Hosmed Medical Aid,<br/>
                    Affinity Health,<br/>
                    Affinity Health Dental Plan,<br/>
                    Getsavvi,<br/>
                    Essential Med,<br/>
                    AGS,<br/>
                    Wesmart,<br/>
                    NBCRFLI,<br/>
                    Rand Water,<br/>
                    NBCPSS (National Bargaining Council for the Private Security Sector),<br/>
                    Acumen,<br/>
                    Asterio
                  </td>
                  {/* <td>10</td>
                  <td>50+</td>
                  <td>100+</td>
                  <td>Unlimited</td> */}
                </tr>
                <tr className="text-xl bg-blue-50">
                  <td className="py-5 pl-10 text-left">Denis</td>
                  <td>
                    Bonitas,
                    Enablemed,
                    KeyHealth,
                    Medshield,
                    PG Group,
                    Thebemed,
                    Transmed
                  </td>
                  {/* <td>Unlimited</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td>
                  <td>Unlimited</td> */}
                </tr>
                <tr className="text-xl ">
                  <td className="py-5 pl-10 text-left">Momentum</td>
                  <td>
                  All options like Evolve, Custom, Incentive, Extender, Summit, Ingwe
                  </td>
                </tr>
                <tr className="text-xl bg-blue-50">
                  <td className="py-5 pl-10 text-left">Gems</td>
                  <td>
                  Tanzanite One, Beryl, Ruby, Emerald Value, Emerald 
                  </td>
                </tr>
                <tr className="text-3xl ">
                  <td className="py-5 pl-10 text-left"></td>
                  <td>
                  AND MANY OTHER MEDICAL AIDS !!!
                  </td>
                </tr>
                {/* <tr className="text-xl">
                  <td className="py-5 pl-10 text-left">Video chat</td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                </tr> */}
                {/* <tr className="text-xl bg-blue-50">
                  <td className="py-5 pl-10 text-left">Realt-Time Collaboration</td>
                  <td>
                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="1.35" x2="16" y2="1.35" stroke="#1F40FF" stroke-width="1.3"></line></svg>
                  </td>
                  <td>
                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="1.35" x2="16" y2="1.35" stroke="#1F40FF" stroke-width="1.3"></line></svg>
                  </td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                </tr> */}
                {/* <tr className="text-xl">
                  <td className="py-5 pl-10 text-left">Support</td>
                  <td>
                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="1.35" x2="16" y2="1.35" stroke="#1F40FF" stroke-width="1.3"></line></svg>
                  </td>
                  <td>
                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="1.35" x2="16" y2="1.35" stroke="#1F40FF" stroke-width="1.3"></line></svg>
                  </td>
                  <td>
                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="1.35" x2="16" y2="1.35" stroke="#1F40FF" stroke-width="1.3"></line></svg>
                  </td>
                  <td>
                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg"><line y1="1.35" x2="16" y2="1.35" stroke="#1F40FF" stroke-width="1.3"></line></svg>
                  </td>
                  <td>
                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.81671 15.0418L0 8.2251L0.90027 7.32483L6.81671 13.2413L19.0997 0.958252L20 1.85852L6.81671 15.0418Z" fill="#1F40FF"></path>
                    </svg>
                  </td>
                </tr> */}
              </tbody>
            </table>
            {/* <div className="flex justify-end">
              <div className="max-w-xs mr-8 flex items-center">
                <p className="mr-6 text-5xl font-bold text-blue-10">
                  8
                  <span className="text-3xl">%</span>
                </p>
                <p className="text-lg">The tell condimentum turpis lorem</p>
              </div>
              <div className="max-w-xs flex items-center">
                <p className="mr-6 text-5xl font-bold text-blue-10">20</p>
                <p className="text-lg">A sad laoreet sit amet nec purus</p>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <Helmet>
        <base />
        <title>Partners</title>
        <meta name="description" content="View all medical aids that the Daily Dentist accepts" />
        {/* <link rel="canonical" href="somelink" /> */}

        {/* <script src="../assets/js/main2.js" type="text/javascript" /> */}
       
      </Helmet> 
    </div>


  );
}